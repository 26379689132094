import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import React from "react";
import config from "../../config.default";

export const ShopifyProvider = ({ children }): JSX.Element => {
  const shopifyConfig = {
    ...config.stores[process.env["GATSBY_SHOPIFY_DEFAULT_SHOP"]],
    ...config.services.shopify,
  };

  const accessTokenLink = new HttpLink({
    uri: `https://${shopifyConfig.shopDomain}/api/${shopifyConfig.apiVersion}/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token": shopifyConfig.accessToken,
    },
  });

  const links = [accessTokenLink];

  const client = new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
