import { useMemo } from "react";

/**
 * Safely push events to Klaviyo (window.klaviyo).
 * Klaviyo is included as an ordinary script via gatsby-ssr.tsx.
 * @see https://developers.klaviyo.com/en/docs/introduction_to_the_klaviyo_object
 */
export function useKlaviyo() {
  return useMemo(
    () => ({
      identify: (payload: Record<string, any>) => {
        klaviyo()?.identify(payload);
      },
      track: (event: string, payload?: Record<string, any>) => {
        klaviyo()?.track(event, payload);
      },
    }),
    [],
  );
}

/**
 * Safely get Klaviyo from the window.
 */
function klaviyo() {
  if ("undefined" === typeof window) return;

  const klaviyo = window.klaviyo;
  if (!klaviyo) return;

  return window.klaviyo;
}

declare global {
  interface Window {
    klaviyo?: {
      identify: (properties: Record<string, any>) => void;
      track: (event: string, properties?: Record<string, any>) => void;
    };
  }
}
