import { useMemo } from "react";

import type { Event } from "types/events";

/**
 * Safely push events to Google Tag Manager (window.dataLayer).
 * Google Tag Manager is included as an ordinary script via gatsby-ssr.tsx.
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 */
export function useGoogleTagManager() {
  return useMemo(
    () => ({
      push: (event: Event) => {
        if ("undefined" === typeof window) return;

        const dataLayer = window.dataLayer;
        if (!dataLayer) return;

        // Extract properties from event.
        const { properties, ...rest } = event;

        // Flatten the payload so that properties are on the same level.
        const payload = { ...properties, ...rest };

        /**
         * If this payload contains an ecommerce object, clear the previous one first.
         * Without this, Google Tag Manager will merge the new object with the old object.
         */
        // @ts-expect-error
        if (payload.ecommerce) {
          dataLayer.push({ ecommerce: null });
        }

        dataLayer.push(payload);
      },
    }),
    [],
  );
}

declare global {
  interface Window {
    dataLayer?: {
      push: (payload: Record<string, any>) => void;
    };
  }
}
