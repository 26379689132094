import React, { ElementType, Fragment, FC } from "react";
import { useApp } from "../../../hooks/useApp";
import { usePage } from "../../../hooks/usePage";
import { useRoutes } from "../../../hooks/useRoutes";
import { ComponentProps } from "../../../types/components";
import {
  SearchResultSanity,
  SearchResultSanityWithResolvedLink,
} from "../../../types/search";

export type SearchResultsArticlesInputProps = ComponentProps & {
  data?: SearchResultSanity[];
  wrapper?: ElementType;
};

export type SearchResultsArticlesOutputProps = Pick<ComponentProps, "ref"> & {
  categoryFallbackText?: string;
  data?: SearchResultSanityWithResolvedLink[];
  heading?: string;
  onClick: () => void;
};

export const withSearchResultsArticles =
  (Component: FC<SearchResultsArticlesOutputProps>) =>
  ({
    name = "SearchResultsArticles",
    data,
    wrapper,
  }: SearchResultsArticlesInputProps) => {
    const { globalStateReducer } = useApp();
    const { routeResolver } = useRoutes();
    const {
      search: {
        articleResultsHeading: heading,
        articleCategoryFallbackText: categoryFallbackText,
      },
    } = usePage();
    const [, dispatch] = globalStateReducer;
    const Wrapper = wrapper || Fragment;

    const filteredData = data
      ?.filter((doc) => doc._type === "article")
      .map((doc) => ({
        ...doc,
        link: {
          url: routeResolver({ item: doc, type: "article" }),
        },
      }));

    const handleOnClick = () => {
      dispatch({
        type: "setActiveSearch",
        payload: false,
      });
    };

    Component.displayName = name;

    if (filteredData.length == 0) {
      return null;
    }

    return (
      <Wrapper>
        <Component
          categoryFallbackText={categoryFallbackText}
          data={filteredData}
          heading={heading}
          onClick={handleOnClick}
        />
      </Wrapper>
    );
  };
