import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
  AnalyticsEventName,
  AnalyticsPageType,
  ShopifySalesChannel,
  useShopifyCookies,
} from "@shopify/hydrogen";

import { Redirect } from "@bared/ui";

import { AtomHydrator } from "~/atoms/AtomHydrator";

import type { GatsbyBrowser } from "~/types/gatsby";

import { Cart } from "~/components/Cart";
import { App } from "~/components/App/App";
import { Header } from "~/components/Header";
import { Tracker } from "~/components/Tracker";
import { LiveChatScripts } from "~/components/LiveChatScripts";
import { GeofencingModal } from "~/components/GeofencingModal";
import { ImpactAnalytics } from "~/components/ImpactAnalytics";
import { SkipLink } from "~/components/Accessibility/SkipLink/SkipLink";

import { UIProvider } from "~/providers/UIProvider";
import { AppProvider } from "~/providers/AppProvider";
import { ShopProvider } from "~/providers/ShopProvider";
import { SanityProvider } from "~/providers/SanityProvider";
import { SearchProvider } from "~/providers/SearchProvider";
import { ShopifyProvider } from "~/providers/ShopifyProvider";
import { WishlistProvider } from "~/providers/WishlistProvider";
import { LegacyLocaleProvider } from "~/providers/LegacyLocaleProvider";

import type { ServerResponseContext, RedirectResponse } from "~/lib/server";

import { rootLoaderDataAtom, siteLoaderDataAtom } from "~/atoms/loaders";

import "./src/tailwind.css";

/**
 * Polyfill Object.hasOwn for older browsers.
 * @see https://bared-melbourne.slack.com/archives/C0434TNDGNQ/p1733790230813129
 */
import "@ungap/has-own";

const queryClient = new QueryClient();

import config from "./config.default.mjs";

const homePageRoutes = config.localisation.map((local) => local.baseRoute);

const sendPageView = (analyticsPageData: any) => {
  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  };
  try {
    sendShopifyAnalytics({
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
    });
  } catch (e) {
    // Safe exit in case of analytics failure to avoid breaking route change.
  }
};

/**
 * Wrap page with providers and UI elements.
 *
 * __Why not use wrapRootElement for providers?__
 * When putting providers in wrapRootElement, they all get rendered twice on every page,
 * because Gatsby wraps not only the page, but also the Head export separately.
 * @see https://github.com/gatsbyjs/gatsby/issues/38269
 */
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  props,
  element,
}) => {
  // Manage Shopify Analytics Cookie
  useShopifyCookies({ hasUserConsent: true });

  const serverData = props.serverData as any;

  const serverResponseContext = serverData?.context as ServerResponseContext;

  if (!serverResponseContext) {
    throw new Error(
      "Could not resolve server response context in gatsby-browser.tsx. Ensure your page exports a getServerData function and you are using the respond method from server context.",
    );
  }

  // If server returned a redirect, do not render.
  if (serverData && [301, 302].includes((serverData as any).status)) {
    const location = (serverData as RedirectResponse["props"]).location;
    return <Redirect href={location} />;
  }

  /**
   * Resolve gender from server data.
   * This is present on the PDP and PLP templates.
   * @todo Move this to ServerDataProvider and access with useGender hook.
   */
  const gender = (() => {
    const gender =
      serverData?.["collectionGender"] || serverData?.["productGender"];

    if (!gender || "string" !== typeof gender) return null;

    // There are a variety of values like "Womens", "Women's", "Women" etc.
    if (gender.toLowerCase().includes("women")) return "women";
    if (gender.toLowerCase().includes("men")) return "men";

    return null;
  })();

  return (
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <AtomHydrator atomValues={[[queryClientAtom, queryClient]]}>
          <LegacyLocaleProvider>
            <SanityProvider>
              <AppProvider config={config}>
                <SearchProvider>
                  <ShopProvider>
                    <ShopifyProvider>
                      <AtomHydrator
                        dangerouslyForceHydrate={true}
                        atomValues={[
                          [rootLoaderDataAtom, serverResponseContext.rootData],
                          [
                            siteLoaderDataAtom,
                            serverResponseContext.siteLoaderData,
                          ],
                        ]}
                      >
                        <LegacyLocaleProvider>
                          <WishlistProvider>
                            <UIProvider>
                              <SkipLink href="#content-main">
                                Skip Navigation
                              </SkipLink>
                              <Cart />
                              <Header gender={gender} />
                              <App>{element}</App>
                              <GeofencingModal />
                              <LiveChatScripts />
                            </UIProvider>
                          </WishlistProvider>
                        </LegacyLocaleProvider>
                        <Tracker />
                      </AtomHydrator>
                      <ImpactAnalytics />
                    </ShopifyProvider>
                  </ShopProvider>
                </SearchProvider>
              </AppProvider>
            </SanityProvider>
          </LegacyLocaleProvider>
        </AtomHydrator>
      </JotaiProvider>
    </QueryClientProvider>
  );
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  /** Start - Sending page view for Shopify analytics */
  let pageType: string = AnalyticsPageType.page;
  const analyticsPageData = {
    currency: "AUD",
    hasUserConsent: true,
    shopId:
      config.stores[
        process.env["GATSBY_SHOPIFY_DEFAULT_SHOP"] || "bared-footwear-au"
      ].shopId,
    shopifySalesChannel: ShopifySalesChannel.headless,
  };

  if (location?.pathname.includes(config.settings.routes.COLLECTION))
    pageType = AnalyticsPageType.collection;
  if (location?.pathname.includes(config.settings.routes.PRODUCT))
    pageType = AnalyticsPageType.product;
  if (location?.pathname.includes(config.settings.routes.ARTICLE))
    pageType = AnalyticsPageType.article;
  if (homePageRoutes?.some((route) => location?.pathname.endsWith(route)))
    pageType = AnalyticsPageType.home;
  if (location?.pathname.includes(config.settings.routes.SEARCH))
    pageType = AnalyticsPageType.search;

  sendPageView({ ...analyticsPageData, pageType });
  /** Ends - Sending page view for Shopify analytics */

  if (
    location?.pathname !== prevLocation?.pathname &&
    ((prevLocation?.pathname.includes(config.settings.routes.COLLECTION) &&
      location?.pathname.includes(config.settings.routes.COLLECTION)) ||
      (prevLocation?.pathname.includes(config.settings.routes.COLLECTION) &&
        location?.pathname.includes(config.settings.routes.SEARCH)) ||
      (prevLocation?.pathname.includes(config.settings.routes.SEARCH) &&
        location?.pathname.includes(config.settings.routes.COLLECTION)))
  )
    window.dispatchEvent(new Event(`refreshSearch`));
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (!window) {
    return;
  }

  window.addEventListener(
    "unhandledrejection",
    (event: PromiseRejectionEvent) => {
      const reasonsToForceReload = [
        /loading chunk \d* failed./i,
        /we couldn't find the correct component chunk with the name component--src/i,
      ];

      const hasValidErrors = reasonsToForceReload.reduce(
        (prev, curr) => prev || curr.test(event.reason),
        false,
      );

      if (!hasValidErrors) {
        return;
      }

      const forceReloadKey = "__fr";
      const queryStringParams = new URLSearchParams(window.location.search);
      const queryStringKeyValues = [...queryStringParams.entries()];
      const hasForceReloadParam = queryStringKeyValues.find(
        ([key]) => key === forceReloadKey,
      );
      const newQueryString = [
        ...queryStringKeyValues,
        [forceReloadKey, Date.now()],
      ].reduce(
        (prev, [key, value]) =>
          `${prev}${prev === "?" ? "" : "&"}${key}=${value}`,
        "?",
      );

      if (!hasForceReloadParam) {
        window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newQueryString}`;
      }
    },
  );
};
