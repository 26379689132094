import { Link } from "@components/Link/Link";
import React from "react";
import tw from "twin.macro";
import { StyledH5 } from "../../Styled/Text";
import {
  withSearchResultsPages,
  SearchResultsPagesOutputProps,
} from "./withSearchResultsPages";

const Container = tw.div``;
const Page = tw.div``;
const Pages = tw.div`
  flex flex-col py-1
`;
const Heading = tw(StyledH5)`
  py-2
`;
const Title = tw.p`
  text-body text-primary
`;

export const SearchResultsPages = withSearchResultsPages(
  ({ data, heading, onClick }: SearchResultsPagesOutputProps) => (
    <Container id="pages">
      <Heading>{heading}</Heading>
      <Pages>
        {data?.map(({ metadata, title, link }) => (
          <Page data-testid="search-results-pages-page">
            <Link onClick={onClick} to={link.url}>
              <Title>{metadata?.title ?? title}</Title>
            </Link>
          </Page>
        ))}
      </Pages>
    </Container>
  ),
);
