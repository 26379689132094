import { ResolvedLink } from "../types/components";
import { useApp } from "./useApp";
import { useCore } from "./useCore";
import { useLocalisation } from "./useLocalisation";

export const useHelper = () => {
  const { navigate } = useLocalisation();

  const removeDuplicateObjectInArray = <T>(arr: T[], keyName: string) => {
    const keyMap: Record<string, boolean> = {};

    return arr.reduce((result: T[], current: T) => {
      const currentKey = current[keyName];
      if (keyMap[currentKey]) {
        return result;
      }

      keyMap[currentKey] = true;

      return [...result, current];
    }, []);
  };

  // from lodash.unescape https://github.com/lodash/lodash/blob/master/unescape.js
  // add &#x27;
  const unescape = (string) => {
    const htmlUnescapes = {
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&#39;": "'",
      "&#x27;": "'",
    };
    const reEscapedHtml = /&(?:amp|#x27|lt|gt|quot|#(0+)?39);/g;
    const reHasEscapedHtml = RegExp(reEscapedHtml.source);

    return string && reHasEscapedHtml.test(string)
      ? string.replace(reEscapedHtml, (entity) => htmlUnescapes[entity] || "'")
      : string || "";
  };

  const position = () => {
    //@ts-ignore
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    );
  };

  /**
   * @deprecated Use window.scrollTo directly.
   */
  const scrollTo = (top: number) => {
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  };

  const {
    config: {
      app: { url },
    },
  } = useApp();

  const {
    helpers: { encodeBase64 },
  } = useCore();

  const getShareWishlistURL = (email, name, internal = false) => {
    const params = encodeBase64(JSON.stringify({ email, name }));
    const sharedUrl = !internal
      ? `${url}/wishlist?share=${params}`
      : `/wishlist?share=${params}`;
    return sharedUrl;
  };

  const handleClickOnCustomContentLink = (link?: ResolvedLink) => {
    if (link?.url && link?.external) {
      window.open(link?.url, "_blank");
    } else if (link?.url) {
      window.location.href = link?.url;
    } else {
      navigate(link?.url, { replace: false });
    }
  };

  return {
    getShareWishlistURL,
    handleClickOnCustomContentLink,
    isJson,
    position,
    removeDuplicateObjectInArray,
    scrollTo,
    unescape,
  };
};
