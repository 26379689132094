import { createClient } from "@sanity/client";
import React, { useMemo, createContext } from "react";

import type { SanityClient } from "@sanity/client";

export interface SanityContextType {
  sanityClient: SanityClient;
}

export const SanityContext = createContext<SanityContextType | null>(null);

export interface SanityProviderProps {
  children: React.ReactNode;
}

export function SanityProvider({ children }: SanityProviderProps) {
  const value = useMemo<SanityContextType>(
    () => ({
      /** Sanity client attached to the appropriate dataset and perspective */
      sanityClient: createClient({
        useCdn: true,
        dataset: "production",
        projectId: "z60zyrm2",
        apiVersion: "2023-06-01",
        perspective: "published",
      }),
    }),
    [],
  );

  return (
    <SanityContext.Provider value={value}>{children}</SanityContext.Provider>
  );
}

SanityProvider.displayName = "SanityProvider";
