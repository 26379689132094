import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  type NormalisedCollection,
  type NormalisedImage,
  type NormalisedProduct,
  type NormalisedProductVariant,
  type ProductStub,
} from "@ts/components";
import {
  MoneyV2,
  type Collection,
  type Metafield,
  type StoreAvailability,
} from "@ts/shopify-storefront";
import { useCallback } from "react";
import { useLocalisationContext } from "./useLocalisation";
import { useSale } from "./useSale";

import { useSite } from "~/hooks/useSite";

export const useShopify = () => {
  const site = useSite();
  const client = useApolloClient();
  const { currentLocale } = useLocalisationContext();
  const { sale, isSaleActive } = useSale();
  const isOnSale = isSaleActive();

  const productNormaliser = (product): NormalisedProduct => {
    const gender = !!product?.metafields
      ? product.metafields.find((metafield) =>
          metafield?.key?.includes("netsuite_class"),
        )
      : null;
    const variants = sortVariantsBySize(
      edgeNormaliser<NormalisedProductVariant>(product?.variants)?.map(
        (variant) => {
          const preorderSize = product?.tags
            ?.filter((tag) => tag.includes(`preorder`))
            .map((item) => item.split(`:`)[1]);
          const variantSize = variant?.selectedOptions?.find(
            (option) => option?.name?.toLowerCase() === "size",
          )?.value;
          const isPreOrder: boolean = preorderSize?.reduce((prev, curr) => {
            return prev || curr === variantSize;
          }, false);
          const badge = getBadgeFromTags(product?.tags);
          const isFinalSale: boolean =
            product?.tags?.includes("finalSale") ||
            badge?.name === "Final Sale";

          const priceV2 = variant.price ?? priceNormaliser(variant.priceV2);

          // Get sale price from tag which matches currency code.
          const salePrice = product.tags
            .find((tag) =>
              tag.startsWith(`sale:price:${priceV2.currencyCode}:`),
            )
            ?.replace(`sale:price:${priceV2.currencyCode}:`, "");

          // Is the sale price lower than the normal price?
          const isSale =
            isOnSale &&
            !!salePrice &&
            Number(salePrice) < Number(priceV2.amount);

          return {
            ...variant,
            priceV2,
            compareAtPriceV2:
              variant?.compareAtPrice ??
              priceNormaliser(variant?.compareAtPriceV2),
            image: imageNormaliser(variant?.image),
            metafields: edgeNormaliser<Metafield>(variant?.metafields),
            storeAvailability: edgeNormaliser<StoreAvailability>(
              variant?.storeAvailability,
            ),
            isPreOrder,
            isFinalSale,
            isSale,
          };
        },
      ),
    );

    const availableForSale = !!variants.some(
      (variant) => !!variant.availableForSale,
    );

    return {
      ...product,
      gender: gender?.value,
      variants,
      availableForSale,
      images: edgeNormaliser<NormalisedImage>(product?.images)?.map((image) =>
        imageNormaliser(image),
      ),
      collections:
        edgeNormaliser<NormalisedCollection>(product?.collections)?.map(
          (collection) => ({
            ...collection,
            image: imageNormaliser(collection?.image),
          }),
        ) || [],
      metafields: edgeNormaliser<Metafield>(product?.metafields),
    };
  };

  const sortVariantsBySize = (variants: NormalisedProductVariant[]) => {
    return variants.sort((a, b) => {
      const sizeA = Number(
        a?.selectedOptions
          ?.find((option) => option?.name?.toLowerCase() === "size")
          ?.value?.split("-")?.[0],
      );
      const sizeB = Number(
        b?.selectedOptions
          ?.find((option) => option?.name?.toLowerCase() === "size")
          ?.value?.split("-")?.[0],
      );

      return sizeA - sizeB;
    });
  };

  const edgeNormaliser = <T>(object): T[] => {
    return object?.edges?.map(({ node }: { node: T }) => node) || object || [];
  };

  const imageNormaliser = (image, size?) => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || ``,
    src: imageUrl(
      image?.url || image?.originalSrc || image?.src || image?.asset?.url || ``,
      size,
    ),
    srcSet: imageSrcSets(
      image?.url || image?.originalSrc || image?.src || image?.asset?.url,
      size,
    ),
  });

  const priceNormaliser = (presentmentPrices?: MoneyV2 | string): MoneyV2 => {
    if (typeof presentmentPrices === "string") {
      return {
        amount: presentmentPrices,
        currencyCode: site.currencyCode,
      } as MoneyV2;
    }

    return presentmentPrices as MoneyV2;
  };

  const parseShopifyRaw = (stub: ProductStub) => {
    const { shopify, ...rest } = stub;
    if (!shopify?.raw) {
      return rest;
    }
    try {
      return productNormaliser({ ...rest, ...JSON.parse(shopify?.raw) });
    } catch (e) {
      console.error(e);
    }
    return rest;
  };

  const imageUrl = (src: string, size = -1): string => {
    const dimensions = `${size}x${size}`;
    const match = src?.match(
      /\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i,
    );

    return match &&
      src?.includes("shopify.com") &&
      !src?.includes("res.cloudinary.com/baredfootwear/image/fetch/f_auto") &&
      size !== -1
      ? `https://res.cloudinary.com/baredfootwear/image/fetch/f_auto/${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(
          /http(s)?:/,
          ``,
        )
      : src;
  };

  const imageSrcSets = (src, size) =>
    src?.includes(`shopify.com`)
      ? [1, 150, 200, 400, 500, 768, 1000, 1500, 2000]
          .filter((set) => !size || (size && size >= set))
          .map((set) => `${imageUrl(src, set.toString())} ${set}w`)
          .join(`,`)
      : "";

  const decodeVariantId = (id?: string) => {
    return id ? id.split(`ProductVariant/`)[1] : id;
  };

  const encodeProductId = (id?: string) => {
    return id ? `gid://shopify/Product/${id}` : id;
  };

  const formatProductGroup = (tags?: string[]) => {
    return tags?.find((tag) => tag.includes("RGroup"));
  };

  const getSaleTagFromTags = useCallback(
    (tags?: string[]): string | undefined => {
      return tags?.find(
        (tag) => tag.includes("sale") && tag.includes(site.currencyCode),
      );
    },
    [site],
  );

  const getBadgeFromTags = useCallback(
    (
      tags?: string[],
      productStatus?: string,
      availableForSale?: boolean,
    ): Badge | undefined => {
      //Resolve notify me/out of stock badge based off inventory count and product status (Convert this to use availableForSale when possible)
      if (!availableForSale && productStatus) {
        return ["Seasonal", "Core"].includes(productStatus)
          ? { name: "Notify Me", variant: "secondary" }
          : { name: "Out Of Stock", variant: "primary" };
      }
      const badgeTags = tags?.filter((tag) => tag?.startsWith("badge"));
      const { defaultCountryCode } = currentLocale;

      if (!badgeTags?.length) {
        return;
      }

      return badgeTags.reduce((prev: Badge | undefined, badgeTag) => {
        const badgeParts = badgeTag.split(":");
        let [, colour, name, countryCode, saleType] = badgeParts || [];

        if (prev) {
          return prev;
        }

        if (!name || !colour) {
          return prev;
        }

        // If the "country code" part looks like a sale type, then it has been omitted.
        if (!!countryCode && ["vip", "sale"].includes(countryCode)) {
          saleType = countryCode;
          countryCode = undefined;
        }

        if (
          !!countryCode &&
          defaultCountryCode?.localeCompare(countryCode) !== 0
        ) {
          return prev;
        }

        if (
          (isOnSale && saleType !== sale?.type) ||
          (!isOnSale && !!saleType)
        ) {
          return prev;
        }

        return {
          name,
          colour,
          saleType,
          countryCode,
        };
      }, undefined);
    },
    [currentLocale, sale, isOnSale],
  );

  const createQueryFromTagPrefix = (
    prefix: string,
    tags: string[],
    queryPrefix: string,
  ) => {
    const tagsWithPrefix = tags?.filter((tag) => tag.startsWith(prefix));
    const prefixWithDelimiter = `${prefix}:`;
    const queryParts = tagsWithPrefix?.map(
      (tag) => `${queryPrefix}:'${tag.split(prefixWithDelimiter)[1]}'`,
    );
    const query = queryParts?.join(" OR ");
    return query;
  };

  return {
    client,
    createQueryFromTagPrefix,
    decodeVariantId,
    edgeNormaliser,
    encodeProductId,
    formatProductGroup,
    getBadgeFromTags,
    getSaleTagFromTags,
    imageSrcSets,
    imageUrl,
    parseShopifyRaw,
    productNormaliser,
    useLazyQuery,
    useMutation,
    useQuery,
  };
};
