import React from "react";

import { CustomComponent } from "@usereactify/search";

import { useSite } from "~/hooks/useSite";

/**
 * Renders a custom "sensor" to add parts to the Reactify Search query.
 */
export function CustomSensor() {
  const site = useSite();

  return (
    <CustomComponent
      componentId="CustomSensor"
      customQuery={() => ({
        query: {
          bool: {
            must_not: [
              {
                wildcard: {
                  [`metafields_custom_exclude_sites.keyword`]: `*${site.metaobjectId}*`,
                },
              },
            ],
          },
        },
      })}
    />
  );
}
