import CountdownTimer from "@components/CountdownTimer/CountdownTimer";
import { FooterSection } from "@components/Footer/FooterSection";
import React from "react";
import AppStyles from "./AppStyles";
import { withApp } from "./withApp";

export const App = withApp(({ children }) => (
  <>
    <AppStyles />
    <main className="pt-[96px]" id="content-main">
      <CountdownTimer />
      {children}
    </main>
    <FooterSection />
  </>
));
