import type { Site } from "~/lib/sites";

export const sites: Site[] = [
  {
    handle: "au",
    urlPrefix: "au",
    locale: "en-AU",
    countryCode: "AU",
    languageCode: "EN",
    currencyCode: "AUD",
    countryCodes: ["AU"],
    metaobjectId: "gid://shopify/Metaobject/106424402056",
  },
  {
    handle: "nz",
    urlPrefix: "nz",
    locale: "en-NZ",
    countryCode: "NZ",
    languageCode: "EN",
    currencyCode: "NZD",
    countryCodes: ["NZ"],
    metaobjectId: "gid://shopify/Metaobject/106424434824",
  },
  {
    handle: "hk",
    urlPrefix: "hk",
    locale: "en-HK",
    currencyCode: "HKD",
    countryCode: "HK",
    languageCode: "EN",
    countryCodes: ["HK"],
    metaobjectId: "gid://shopify/Metaobject/106424467592",
  },
  {
    handle: "sg",
    urlPrefix: "sg",
    locale: "en-SG",
    countryCode: "SG",
    languageCode: "EN",
    currencyCode: "SGD",
    countryCodes: ["SG"],
    metaobjectId: "gid://shopify/Metaobject/106424500360",
  },
  {
    handle: "uk",
    urlPrefix: "uk",
    locale: "en-GB",
    countryCode: "GB",
    languageCode: "EN",
    currencyCode: "GBP",
    countryCodes: ["UK", "GB"],
    metaobjectId: "gid://shopify/Metaobject/106424533128",
  },
  {
    handle: "us",
    urlPrefix: "us",
    locale: "en-US",
    countryCode: "US",
    languageCode: "EN",
    currencyCode: "USD",
    countryCodes: ["US", "ROW"],
    metaobjectId: "gid://shopify/Metaobject/106424565896",
  },
];
