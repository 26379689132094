import { atom } from "jotai";

import { sites } from "~/config/sites";

import type { ServerResponseContext } from "~/lib/server";

const defaultSite = sites.find((site) => "us" === site.handle);
if (!defaultSite)
  throw new Error(
    "Default site could not be resolved when initializing loader atoms.",
  );

export const rootLoaderDataAtom = atom<ServerResponseContext["rootData"]>({
  seo: {},
  settingSubscribe: null,
  storefrontApiVersion: "2025-01",
  shop: {
    handle: "production",
    shopifyDomain: "bared-footwear-au.myshopify.com",
    checkoutDomain: "checkout.baredfootwear.com",
    storefrontId: "36092",
    publicStorefrontToken: "59c55f77c2ca07bbaec00c9ca19b65c9",
  },
  isHydrated: false,
});

export const siteLoaderDataAtom = atom<ServerResponseContext["siteLoaderData"]>(
  {
    sites,
    site: defaultSite,
    localSite: defaultSite,
    geofencingLocal: null,
    geofencingCurrent: null,
    viewerContext: { countryCode: "AU", countryName: "Australia" },
    liveChat: {
      enabled: true,
      // International zendesk key.
      key: "1b65676d-33b7-40f5-a795-f66257e22f01",
    },
    market: {
      handle: "united-states",
      legacyId: "34439561352",
      id: "gid://shopify/Market/34439561352",
    },
    isHydrated: false,
  },
);
