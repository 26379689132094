import { HeadlessCore } from "@dotdev/headless-core-app";
import gql from "graphql-tag";

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Image {
    id
    altText
    url
  }
`;

export const MEDIA_IMAGE_FRAGMENT = gql`
  fragment MediaImageFragment on MediaImage {
    alt
    id
    image {
      ...ImageFragment
    }
  }
`;

export const MEDIA_VIDEO_FRAGMENT = gql`
  fragment MediaVideoFragment on Video {
    alt
    id
    previewImage {
      ...ImageFragment
    }
    sources {
      format
      height
      mimeType
      url
      width
    }
  }
`;

export const MEDIA_EXTERNAL_VIDEO_FRAGMENT = gql`
  fragment MediaExternalVideoFragment on ExternalVideo {
    alt
    embeddedUrl
    id
    previewImage {
      ...ImageFragment
    }
  }
`;

export const VARIANT_FRAGMENT = gql`
  fragment VariantFragment on ProductVariant {
    id
    sku
    title
    weight
    weightUnit
    availableForSale
    currentlyNotInStock
    quantityAvailable
    requiresShipping
    storeAvailability(first: 20) {
      edges {
        node {
          location {
            id
            name
            address {
              address1
              address2
              city
              province
              provinceCode
              country
              countryCode
              phone
              zip
            }
          }
          available
          quantityAvailable
        }
      }
    }
    selectedOptions {
      name
      value
    }
    image {
      ...ImageFragment
    }
    ...PriceFragment
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
  }
  ${IMAGE_FRAGMENT}
  ${HeadlessCore.graphql.fragments.PRICE_FRAGMENT}
`;

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    productType
    handle
    title
    description
    descriptionHtml
    productType
    vendor
    tags
    availableForSale
    publishedAt
    updatedAt
    createdAt
    options {
      id
      name
      optionValues {
        name
      }
    }
    priceRange {
      ...PriceRangeFragment
    }
    images(first: $firstImages) {
      edges {
        node {
          ...ImageFragment
        }
      }
    }
    media(first: $firstMedia) {
      edges {
        node {
          mediaContentType
          ...MediaImageFragment
          ...MediaVideoFragment
          ...MediaExternalVideoFragment
        }
      }
    }
    metafields(
      identifiers: [
        { key: "productName", namespace: "lightspeedConnector" }
        { key: "productColour", namespace: "lightspeedConnector" }
        { key: "description_callout", namespace: "custom" }
        { key: "hide_footbed_image", namespace: "custom" }
        { key: "shoe_care_upsell", namespace: "custom" }
        { key: "netsuite_class", namespace: "custom" }
        { key: "netsuite_category", namespace: "custom" }
        { key: "netsuite_sub_category", namespace: "custom" }
        { key: "netsuite_style_name", namespace: "custom" }
        { key: "netsuite_status", namespace: "custom" }
      ]
    ) {
      id
      key
      namespace
      value
      type
    }
    variants(first: $firstVariants) {
      edges {
        node {
          ...VariantFragment
        }
      }
    }
    collections(first: $firstCollections) {
      edges {
        node {
          id
          title
          handle
          description
          image {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${MEDIA_IMAGE_FRAGMENT}
  ${MEDIA_VIDEO_FRAGMENT}
  ${MEDIA_EXTERNAL_VIDEO_FRAGMENT}
  ${HeadlessCore.graphql.fragments.PRICE_RANGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`;
export const VARIANT_WITH_PRODUCT_FRAGMENT = gql`
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      description
      handle
      id
      productType
      tags
      vendor
      metafields(
        identifiers: [
          { key: "netsuite_class", namespace: "custom" }
          { key: "netsuite_category", namespace: "custom" }
          { key: "netsuite_sub_category", namespace: "custom" }
        ]
      ) {
        id
        key
        namespace
        value
        type
      }
    }
  }
  ${VARIANT_FRAGMENT}
`;

export const CHECKOUT_FRAGMENT = gql`
  fragment CheckoutFragment on Checkout {
    id
    ready
    requiresShipping
    note
    buyerIdentity {
      countryCode
    }
    paymentDueV2 {
      amount
      currencyCode
    }
    webUrl
    orderStatusUrl
    taxExempt
    taxesIncluded
    currencyCode
    totalTax {
      amount
      currencyCode
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    completedAt
    createdAt
    updatedAt
    email
    appliedGiftCards {
      ...AppliedGiftCardFragment
    }
    shippingAddress {
      ...MailingAddressFragment
    }
    shippingLine {
      handle
      price {
        amount
        currencyCode
      }
      title
    }
    customAttributes {
      key
      value
    }
    order {
      id
      processedAt
      orderNumber
      subtotalPriceV2 {
        amount
        currencyCode
      }
      totalShippingPrice {
        amount
        currencyCode
      }
      totalTaxV2 {
        amount
        currencyCode
      }
      totalPriceV2 {
        amount
        currencyCode
      }
      currencyCode
      totalRefunded {
        amount
        currencyCode
      }
      customerUrl
      shippingAddress {
        ...MailingAddressFragment
      }
      lineItems(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            title
            variant {
              ...VariantWithProductFragment
            }
            quantity
            customAttributes {
              key
              value
            }
          }
        }
      }
    }
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          variant {
            ...VariantWithProductFragment
          }
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
          }
        }
      }
    }
    discountApplications(first: 10) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... on DiscountCodeApplication {
            code
          }
          allocationMethod
          targetSelection
          targetType
          value {
            ... on PricingPercentageValue {
              percentage
            }
            ... on MoneyV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
  ${HeadlessCore.graphql.fragments.APPLIED_GIFT_CARD_FRAGMENT}
  ${HeadlessCore.graphql.fragments.MAILING_ADDRESS_FRAGMENT}
  ${VARIANT_WITH_PRODUCT_FRAGMENT}
`;

export const GET_CHECKOUT = gql`
  query GET_CHECKOUT($countryCode: CountryCode!, $checkoutId: ID!)
  @inContext(country: $countryCode) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_CREATE = gql`
  mutation CHECKOUT_CREATE(
    $countryCode: CountryCode!
    $input: CheckoutCreateInput!
  ) @inContext(country: $countryCode) {
    checkoutCreate(input: $input) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_SHIPPING_LINE_UPDATE = gql`
  mutation checkoutShippingLineUpdate(
    $countryCode: CountryCode!
    $checkoutId: ID!
    $shippingRateHandle: String!
  ) @inContext(country: $countryCode) {
    checkoutShippingLineUpdate(
      checkoutId: $checkoutId
      shippingRateHandle: $shippingRateHandle
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_ATTRIBUTES_UPDATE = gql`
  mutation checkoutAttributesUpdateV2(
    $countryCode: CountryCode!
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
  ) @inContext(country: $countryCode) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      userErrors {
        field
        message
      }
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_LINE_ITEM_ADD = gql`
  mutation checkoutLineItemsAdd(
    $countryCode: CountryCode!
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) @inContext(country: $countryCode) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace(
    $countryCode: CountryCode!
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) @inContext(country: $countryCode) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation checkoutLineItemsRemove(
    $countryCode: CountryCode!
    $checkoutId: ID!
    $lineItemIds: [ID!]!
  ) @inContext(country: $countryCode) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const GET_CUSTOMER_ORDERS = gql`
  query GET_CUSTOMER_ORDERS(
    $countryCode: CountryCode!
    $customerAccessToken: String!
    $first: Int!
    $reverse: Boolean = false
  ) @inContext(country: $countryCode) {
    customer(customerAccessToken: $customerAccessToken) {
      orders(first: $first, reverse: $reverse) {
        edges {
          cursor
          node {
            id
            name
            orderNumber
            currencyCode
            financialStatus
            fulfillmentStatus
            statusUrl
            customerUrl
            originalTotalPrice {
              amount
              currencyCode
            }
            subtotalPriceV2 {
              amount
              currencyCode
            }
            successfulFulfillments {
              fulfillmentLineItems(first: 50) {
                edges {
                  node {
                    lineItem {
                      title
                      quantity
                    }
                  }
                }
              }
            }
            totalShippingPrice {
              amount
              currencyCode
            }
            totalTaxV2 {
              amount
              currencyCode
            }
            totalPriceV2 {
              amount
              currencyCode
            }
            totalRefunded {
              amount
              currencyCode
            }
            processedAt
            canceledAt
            shippingAddress {
              address1
              address2
              firstName
              lastName
              city
              province
              zip
              country
            }
            lineItems(first: 100) {
              edges {
                cursor
                node {
                  title
                  variant {
                    ...VariantWithProductFragment
                  }
                  quantity
                  customAttributes {
                    key
                    value
                  }
                  discountAllocations {
                    allocatedAmount {
                      amount
                      currencyCode
                    }
                  }
                  currentQuantity
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
            currentTotalPrice {
              amount
              currencyCode
            }
            currentSubtotalPrice {
              amount
              currencyCode
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
  ${VARIANT_WITH_PRODUCT_FRAGMENT}
`;

export const CHECKOUT_SHIPPING_ADDRESS_UPDATE = gql`
  mutation checkoutShippingAddressUpdateV2(
    $countryCode: CountryCode!
    $shippingAddress: MailingAddressInput!
    $checkoutId: ID!
  ) @inContext(country: $countryCode) {
    checkoutShippingAddressUpdateV2(
      shippingAddress: $shippingAddress
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${HeadlessCore.graphql.fragments.CHECKOUT_USER_ERROR_FRAGMENT}
`;

export const GET_SHIPPING_RATES = gql`
  query GET_SHIPPING_RATES($countryCode: CountryCode!, $checkoutId: ID!)
  @inContext(country: $countryCode) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutFragment
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
            title
          }
        }
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_CUSTOMER_DISASSOCIATE = gql`
  mutation checkoutCustomerDisassociateV2(
    $countryCode: CountryCode!
    $checkoutId: ID!
  ) @inContext(country: $countryCode) {
    checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${HeadlessCore.graphql.fragments.CHECKOUT_USER_ERROR_FRAGMENT}
`;

export const GET_PRODUCT_LIGHT = gql`
  query (
    $countryCode: CountryCode!
    $handle: String!
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      id
      handle
      description
      title
      tags
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
      metafields(
        identifiers: [
          { key: "netsuite_class", namespace: "custom" }
          { key: "netsuite_category", namespace: "custom" }
          { key: "netsuite_sub_category", namespace: "custom" }
        ]
      ) {
        id
        key
        namespace
        value
        type
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...VariantFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`;

export const GET_PRODUCT = gql`
  query (
    $countryCode: CountryCode!
    $handle: String!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_WITH_ACCESSORIES = gql`
  query (
    $countryCode: CountryCode!
    $handle: String!
    $accessoriesQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    accessories: products(query: $accessoriesQuery, first: $firstVariants) {
      edges {
        node {
          handle
          tags
          variants(first: $firstVariants) {
            edges {
              node {
                ...VariantFragment
              }
            }
          }
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_WITH_RECOMMENDATIONS = gql`
  query (
    $countryCode: CountryCode!
    $id: ID!
    $handle: String!
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $firstCollections: Int
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    recommendations: productRecommendations(productId: $id) {
      handle
      tags
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_WITH_RECOMMENDATIONS_AND_ACCESSORIES = gql`
  query (
    $countryCode: CountryCode!
    $id: ID!
    $handle: String!
    $accessoriesQuery: String
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $firstCollections: Int
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    recommendations: productRecommendations(productId: $id) {
      handle
      tags
    }
    accessories: products(query: $accessoriesQuery, first: $firstVariants) {
      edges {
        node {
          handle
          tags
          variants(first: $firstVariants) {
            edges {
              node {
                ...VariantFragment
              }
            }
          }
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCTS_BY_HANDLE = (handles) => gql`
  query(
    $countryCode: CountryCode!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    ${
      !handles?.length
        ? `
      productEmpty: productByHandle(handle: "") {
        ...ProductFragment
      }`
        : handles?.map(
            (handle) => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        ...ProductFragment
      }`,
          )
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCTS_BY_HANDLE_LIGHT = (handles) => gql`
query(
  $countryCode: CountryCode!
  $firstImages: Int
  $firstVariants: Int
) @inContext(country: $countryCode) {
  ${
    handles === null || handles === void 0
      ? void 0
      : handles.map(
          (handle) => `
    product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
      id
      handle
      title
      tags
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
      metafields(
        identifiers: [
          { key: "netsuite_class", namespace: "custom" }
          { key: "netsuite_category", namespace: "custom" }
          { key: "netsuite_sub_category", namespace: "custom" }
        ]
      ) {
        id
        key
        namespace
        value
        type
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...VariantFragment
          }
        }
      }
    }
  `,
        )
  }
}
${IMAGE_FRAGMENT}
${VARIANT_FRAGMENT}
`;

export const GET_PRODUCT_LITE = gql`
  query (
    $countryCode: CountryCode!
    $handle: String!
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      id
      handle
      description
      title
      tags
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...VariantFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`;

export const GET_COLLECTION_PRODUCT_COMPLETE = gql`
  query (
    $countryCode: CountryCode!
    $handle: String!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstMetafields: Int
    $firstProducts: Int
    $firstVariants: Int
    $afterVariants: String
  ) @inContext(country: $countryCode) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
      products(first: $firstProducts) {
        edges {
          node {
            ...ProductFragment
          }
        }
      }
    }
  }
  ${HeadlessCore.graphql.fragments.COLLECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`;

export const GET_COLLECTION_PRODUCT_REFERENCES = gql`
  query ($handle: String!, $firstProducts: Int) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
      products(first: $firstProducts) {
        edges {
          node {
            id
            handle
          }
        }
      }
    }
  }
  ${HeadlessCore.graphql.fragments.COLLECTION_FRAGMENT}
`;

export const CHECKOUT_CUSTOMER_ASSOCIATE = gql`
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${HeadlessCore.graphql.fragments.CHECKOUT_USER_ERROR_FRAGMENT}
`;

export const CHECKOUT_DISCOUNT_APPLY = gql`
  mutation checkoutDiscountCodeApplyV2(
    $countryCode: CountryCode!
    $discountCode: String!
    $checkoutId: ID!
  ) @inContext(country: $countryCode) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${HeadlessCore.graphql.fragments.CHECKOUT_USER_ERROR_FRAGMENT}
`;

export const CHECKOUT_GIFTCARDS_APPEND = gql`
  mutation checkoutGiftCardsAppend(
    $countryCode: CountryCode!
    $giftCardCodes: [String!]!
    $checkoutId: ID!
  ) @inContext(country: $countryCode) {
    checkoutGiftCardsAppend(
      giftCardCodes: $giftCardCodes
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${HeadlessCore.graphql.fragments.CHECKOUT_USER_ERROR_FRAGMENT}
`;

export const CUSTOMER_ACTIVATE = gql`
  mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
    customerActivate(id: $id, input: $input) {
      customer {
        id
        email
        tags
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorFragment
      }
    }
  }
  ${HeadlessCore.graphql.fragments.CUSTOMER_USER_ERROR_FRAGMENT}
`;
