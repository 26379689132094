import type { UserError } from "@ts/shopify-storefront";
import { useCallback, useState } from "react";
import { useApp } from "./useApp";
import { useLocation } from "./useLocation";

type FunctionResponse<T = unknown> = {
  status?: "success" | "error";
  body?: string | T;
};

export const useFunctions = () => {
  const { shopifyStore } = useLocation();

  const {
    config: {
      services: { functions },
      stores,
    },
  } = useApp();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<UserError[] | string[]>([]);
  const dataset = stores[shopifyStore]?.sanityDataset;

  const request = useCallback(
    async (endpoint, data, overrides: RequestInit = {}) => {
      setLoading(true);
      setErrors([]);

      const local =
        "development" === process.env.NODE_ENV &&
        process.env.GATSBY_CLOUD_PREVIEW !== "1";

      return fetch(
        `${local ? "/api" : functions?.endpoint}${endpoint}?shop=${shopifyStore}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          ...overrides,
          ...(overrides.method !== "GET" && { body: JSON.stringify(data) }),
        },
      )
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          return result;
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setErrors([error]);
          return error;
        });
    },
    [setLoading, setErrors, fetch, shopifyStore],
  );

  const returnsSubmit = useCallback<
    <T, K>(data: T) => Promise<FunctionResponse<K>>
  >(
    async (data) => {
      const { status, body } = await request("/returns/submit", { data });
      return { status, body };
    },
    [request],
  );

  const searchSanity = useCallback<
    <T>(terms: string) => Promise<FunctionResponse<T>>
  >(
    async (terms) => {
      const { status, body } = await request("/search", {
        terms,
        store: dataset,
      });
      return { status, body };
    },
    [request],
  );

  return {
    errors,
    loading,
    returnsSubmit,
    searchSanity,
    setErrors,
  };
};
