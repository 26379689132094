import ky from "ky";
import { useMemo } from "react";

import {
  useQuery,
  useMutation,
  useIsMutating,
  useIsFetching,
  useQueryClient,
} from "@tanstack/react-query";

import type { CartLineAccessoryType } from "types/cart";

import type {
  CartResponse,
  UpdateCartRequest,
  UpdateCartResponse,
} from "~/api/cart";

import { useStore } from "~/store";

import { useSite } from "./useSite";

import { useKlaviyo } from "./useKlaviyoNew";
import { useTripleWhale } from "./useTripleWhale";
import { useShopifyAnalytics } from "./useShopifyAnalytics";
import { useGoogleTagManager } from "./useGoogleTagManager";

export function useCart() {
  const site = useSite();

  return useQuery({
    queryKey: ["cart"],
    queryFn: async () => {
      const res = await ky
        .get("/api/cart", { headers: { "X-Site-Handle": site.handle } })
        .json<CartResponse>();
      return res.cart;
    },
  });
}

export function useCartCount() {
  const { data, isPending } = useCart();
  if (isPending) return undefined;
  return data?.totalQuantity ?? 0;
}

export function useCartIsLoading() {
  const isFetching = useIsFetching({ queryKey: ["cart"] });
  const isMutating = useIsMutating({ mutationKey: ["cart"] });
  return 0 < isFetching || 0 < isMutating;
}

export function useCartUpdate() {
  const site = useSite();

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["cart"],
    mutationFn: async (input: UpdateCartRequest) => {
      const res = await ky
        .post("/api/cart", {
          json: input,
          headers: { "X-Site-Handle": site.handle },
        })
        .json<UpdateCartResponse>();

      if (res.cart) {
        queryClient.setQueryData(["cart"], res.cart);
      }

      return res;
    },
  });
}

export function useCartAdd() {
  const openCart = useStore((state) => state.openCart);

  const { mutateAsync, isPending } = useCartUpdate();

  const klaviyo = useKlaviyo();
  const tripleWhale = useTripleWhale();
  const shopifyAnalytics = useShopifyAnalytics();
  const googleTagManager = useGoogleTagManager();

  return useMemo(
    () => ({
      isPending,
      addToCart: async ({
        quantity,
        merchandiseId,
        attributes = [],
      }: {
        quantity: number;
        merchandiseId: string;
        attributes?: { key: string; value: string }[];
      }) => {
        // Send the mutation.
        const res = await mutateAsync({
          quantity,
          attributes,
          merchandiseId,
          action: "ADD_TO_CART",
        });

        // Open the cart drawer.
        openCart();

        // The newly added item will always be the first item.
        const cartLine = res.cart?.lines[0];

        // Emit analytics events.
        if (!!res.cart && !!cartLine) {
          googleTagManager.push({
            event: "add_to_cart",
            ecommerce: {
              currency: res.cart.custom.totalAmount.currencyCode,
              value: parseFloat(cartLine.custom.price.amount),
              items: [cartLine.custom.googleAnalytics],
            },
          });

          klaviyo.track("Cart Add", cartLine.custom.klaviyo);

          tripleWhale.trackAddToCart(
            cartLine.merchandise.id.replace(
              "gid://shopify/ProductVariant/",
              "",
            ),
            cartLine.quantity,
          );

          shopifyAnalytics.track("ADD_TO_CART", {
            cartId: res.cart.id,
          });
        }

        // Return the mutation response.
        return res;
      },
    }),
    [
      klaviyo,
      openCart,
      isPending,
      mutateAsync,
      tripleWhale,
      googleTagManager,
      shopifyAnalytics,
    ],
  );
}

export function useCartLineItemRemove() {
  const { mutateAsync, isPending } = useCartUpdate();

  const queryClient = useQueryClient();

  const klaviyo = useKlaviyo();
  const googleTagManager = useGoogleTagManager();

  return useMemo(
    () => ({
      isPending,
      removeLineItem: async ({ cartLineId }: { cartLineId: string }) => {
        // Get current cart from state.
        const cart = queryClient.getQueryData<CartResponse["cart"]>(["cart"]);

        // Get the cart line about to be removed.
        const cartLine = cart?.lines.find(
          (cartLine) => cartLine.id === cartLineId,
        );

        // Send the mutation.
        const res = await mutateAsync({
          cartLineId,
          action: "REMOVE_FROM_CART",
        });

        // Emit analytics events.
        if (!!cart && !!cartLine) {
          googleTagManager.push({
            event: "remove_from_cart",
            ecommerce: {
              currency: cart.custom.totalAmount.currencyCode,
              value: parseFloat(cartLine.custom.price.amount),
              items: [cartLine.custom.googleAnalytics],
            },
          });

          klaviyo.track("Cart Remove", cartLine.custom.klaviyo);
        }

        // Return the mutation response.
        return res;
      },
    }),
    [mutateAsync, queryClient, googleTagManager, klaviyo],
  );
}

export function useCartToggleAccessory() {
  const { mutate, isPending } = useCartUpdate();

  return useMemo(
    () => ({
      isPending,
      toggleAccessory: ({
        enabled,
        cartLineId,
        accessoryType,
      }: {
        enabled: boolean;
        cartLineId: string;
        accessoryType: CartLineAccessoryType;
      }) =>
        mutate({
          enabled,
          cartLineId,
          accessoryType,
          action: "TOGGLE_ACCESSORY",
        }),
    }),
    [mutate],
  );
}

export function useCartToggle() {
  const _openCart = useStore((state) => state.openCart);
  const _closeCart = useStore((state) => state.closeCart);
  const cartIsOpen = useStore((state) => state.cartIsOpen);

  const queryClient = useQueryClient();

  const googleTagManager = useGoogleTagManager();

  return useMemo(
    () => ({
      openCart: () => {
        _openCart();

        // Get current cart from state.
        const cart = queryClient.getQueryData<CartResponse["cart"]>(["cart"]);

        // Emit analytics events.
        if (cart) {
          googleTagManager.push({
            event: "view_cart",
            ecommerce: {
              currency: cart.custom.totalAmount.currencyCode,
              value: parseFloat(cart.custom.totalAmount.amount),
              items: cart.lines.map(
                (cartLine) => cartLine.custom.googleAnalytics,
              ),
            },
          });
        }
      },
      closeCart: () => {
        _closeCart();

        queryClient.setQueryData(["cart"], (cart: CartResponse["cart"]) => {
          if (cart && cart.custom.flashMessage) {
            cart.custom.flashMessage = null;
          }

          return cart;
        });
      },
      cartIsOpen,
    }),
    [_openCart, _closeCart, cartIsOpen, queryClient, googleTagManager],
  );
}
