import React from "react";
import { Link as GatsbyLink } from "gatsby";

import type { LinkProps } from "@bared/ui";

import { useHref } from "~/hooks/useHref";

export function Link({
  href,
  onClick,
  className,
  onMouseOut,
  onMouseOver,
  activeClassName,
  partiallyActive,
  children,
  ...props
}: LinkProps) {
  const to = useHref(href ?? "/");

  // Render an ordinary anchor for external links.
  if (to.startsWith("http")) {
    return (
      <a href={to} className={className} {...props}>
        {children}
      </a>
    );
  }

  // Render a GatsbyLink for internal links.
  return (
    <GatsbyLink
      to={to}
      onClick={onClick}
      className={className}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
    >
      {children}
    </GatsbyLink>
  );
}
