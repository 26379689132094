import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXT9Rsvr_kNvhhIqPpAeG-EGLLci8iGy8",
  authDomain: "bared-footwear-website.firebaseapp.com",
  databaseURL: "https://bared-footwear-website.firebaseio.com",
  projectId: "bared-footwear-website",
  storageBucket: "bared-footwear-website.appspot.com",
  messagingSenderId: "37454649381",
  appId: "1:37454649381:web:fc1907e0d14d1516e787cc",
};

const firebase = initializeApp(firebaseConfig);

const firestore = getFirestore(firebase);

export { firebase, firestore };
