import { useMemo } from "react";

import { useRouteLoaderData } from "./useRouteLoaderData";

export function useLiveChat() {
  const {
    liveChat: { key, enabled },
  } = useRouteLoaderData("routes/$site");

  return useMemo(
    () => ({
      /**
       * Zendesk key for widget.
       */
      key,
      /**
       * Is the live chat widget enabled.
       */
      enabled,
      /**
       * Open the live chat widget.
       * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core/#open
       */
      open: () => {
        if (!window["zE"]) return;
        window["zE"]("messenger", "open");
      },
      /**
       * Close the live chat widget.
       * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core/#close
       */
      close: () => {
        if (!window["zE"]) return;
        window["zE"]("messenger", "close");
      },
      /**
       * Show the live chat widget.
       * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core/#show
       */
      show: () => {
        if (!window["zE"]) return;
        window["zE"]("messenger", "show");
      },
      /**
       * Hide the live chat widget.
       * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core/#hide
       */
      hide: () => {
        if (!window["zE"]) return;
        window["zE"]("messenger", "hide");
      },
    }),
    [key, enabled],
  );
}
