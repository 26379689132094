import ky, { HTTPError, Options } from "ky";

import { atomWithQuery } from "jotai-tanstack-query";

import type { RecentlyViewedResponse } from "~/api/recently-viewed";

/**
 * Return recently viewed products from /api/recently-viewed.
 */
export const recentlyViewedAtom = atomWithQuery(() => ({
  queryKey: ["recently-viewed"],
  refetchOnWindowFocus: false,
  queryFn: () => ky.get("/api/recently-viewed").json<RecentlyViewedResponse>(),
}));

/**
 * Hooks for ky requests.
 */
export const hooks: Options["hooks"] = {
  beforeError: [
    /**
     * Hook for extracting errors from responses.
     * @see https://github.com/sindresorhus/ky#hooksbeforeerror
     */
    async (error: HTTPError) => {
      try {
        const errorJson = await error.response.json();
        if (
          errorJson &&
          "object" === typeof errorJson &&
          "errors" in errorJson &&
          Array.isArray(errorJson.errors)
        ) {
          error.messages = errorJson.errors as string[];
        }
        return error;
      } catch {
        return error;
      }
    },
  ],
};

/**
 * Augment Error to include an optional array of messages.
 * This allows us to include multiple error messages in a single thrown error object.
 */
declare global {
  interface Error {
    messages?: string[];
  }
}
