import React, { useCallback, useEffect, useRef } from "react";

import { ReactifySearchProvider, Sensors } from "@usereactify/search";

import {
  Link,
  useNavStack,
  HeaderStack,
  NavOverlay,
  NavConfigProvider,
  Ticker,
  TickerSection,
  SanityLink,
} from "@bared/ui";

import type { NavigationMenuViewedEvent } from "types/events";

import { useApp } from "~/hooks/useApp";
import { useLiveChat } from "~/hooks/useLiveChat";
import { useWishlistContext } from "~/hooks/useWishlist";
import { useAnnouncement } from "~/hooks/useAnnouncement";
import { useRouteLoaderData } from "~/hooks/useRouteLoaderData";
import { useLocalisationContext } from "~/hooks/useLocalisation";
import { useCartCount, useCartToggle } from "~/hooks/useCartNew";
import { useGoogleTagManager } from "~/hooks/useGoogleTagManager";

import { ReviewsProvider } from "~/providers/ReviewsProvider";

import { CustomSensor } from "~/components/CustomSensor";
import { SearchForm } from "~/components/Search/Form/SearchForm";

export interface HeaderProps {
  /** Gender used to calculate root menu */
  gender?: string | null;
}

export function Header({ gender }: HeaderProps) {
  const { navConfig } = useRouteLoaderData("routes/$site");
  const { market } = useRouteLoaderData("routes/$site");
  const { items } = useAnnouncement();
  const { count: wishlistCount } = useWishlistContext();
  const { activeSearch, handleSearchToggle } = useSearch();
  const { navigate } = useLocalisationContext();

  const cartCount = useCartCount();
  const { openCart } = useCartToggle();

  const handleRedirect = useCallback(
    (type: "redirect" | "search", url: string) => {
      handleSearchToggle();
      navigate(url, { type });
    },
    [handleSearchToggle, navigate],
  );

  if (!navConfig) return null;

  /**
   * Determine if the viewport is bigger than the md breakpoint.
   * This is purposely not reactive i.e. you need to refresh the page to change it.
   */
  const isDesktop =
    "undefined" !== typeof window &&
    768 <= window.innerWidth &&
    !window.matchMedia("(pointer: coarse)").matches;

  const leftContent = [
    <div className="flex w-1/2 items-center justify-start text-caption text-white md:pl-zero">
      <div className="hidden h-full content-center border-r-[1px] border-white border-opacity-30 pr-mini md:block">
        {"AUD"}
      </div>
      <Ticker className="!justify-start pl-zero md:pl-mini">
        {items?.map((item, index) => (
          <TickerSection
            id={`announcement-${index}`}
            key={`announcement-${index}`}
          >
            {item.textLink && item.textLink.length > 0 ? (
              <SanityLink
                className="text-caption text-white"
                data={item.textLink[0]}
                target={item.textLink[0]._type === "link" ? "_blank" : "_self"}
                rel="noopener noreferrer"
              >
                {item.title}
              </SanityLink>
            ) : (
              <span className="text-caption text-white">{item.title}</span>
            )}
          </TickerSection>
        ))}
      </Ticker>
    </div>,
  ];

  const rightContent = [
    <div className="flex w-1/2 items-center justify-end gap-mini text-caption text-white [&>:not(:first-child)]:hidden md:[&>:not(:first-child)]:block">
      <Link href="/account"> My Account </Link>
      <Link href="https://au.support.baredfootwear.com/hc/en-au/p/contact">
        {" "}
        Contact Us{" "}
      </Link>
      <Link href="/store-locations"> Stores </Link>
    </div>,
  ];

  // Calculate a root menu based on the gender.
  const rootMenus = (() => {
    if ("women" === gender) return ["root", "women"];
    if ("men" === gender) return ["root", "men"];
    return ["root"];
  })();

  return (
    <NavConfigProvider config={navConfig} rootMenus={rootMenus}>
      <div className="fixed left-zero right-zero top-zero z-50">
        <div
          className={`transition-layout duration-slow md:py-0 fixed top-[32px] h-[60px] w-full bg-white md:top-[0px] md:h-[86px] ${
            activeSearch ? "z-100" : "hidden"
          }`}
          onKeyDown={(e) => e.key === "Escape" && handleSearchToggle()}
          tabIndex={0}
        >
          <div className="container mx-auto">
            <ReviewsProvider>
              {activeSearch && (
                <ReactifySearchProvider
                  key={`search-${activeSearch}`}
                  mode="instant-search"
                  includeFields={["*"]}
                  market={market.legacyId}
                  additionalComponentIds={["CustomSensor"]}
                  shopifyPermanentDomain="bared-footwear-au.myshopify.com"
                  theme={{
                    colors: {
                      textColor: "rgb(43,43,43)",
                      primaryTextColor: "rgb(43,43,43)",
                      primaryColor: "rgb(43,43,43)",
                      titleColor: "rgb(43,43,43)",
                      alertColor: "rgb(43,43,43)",
                    },
                  }}
                  onRedirect={handleRedirect}
                >
                  <Sensors />
                  <CustomSensor />
                  <SearchForm className="h-full w-full items-center pr-mini xl:flex" />
                </ReactifySearchProvider>
              )}
            </ReviewsProvider>
          </div>
        </div>
        <HeaderStack
          isDesktop={isDesktop}
          onToggleCart={openCart}
          leftContent={leftContent}
          cartCount={cartCount ?? 0}
          rightContent={rightContent}
          wishlistCount={wishlistCount ?? 0}
          onSearchToggle={handleSearchToggle}
        />
      </div>
      {!isDesktop && <NavOverlay />}
      <LiveChatToggler />
      <AnalyticsSubscriber />
    </NavConfigProvider>
  );
}

function useSearch() {
  const { globalStateReducer } = useApp();
  const [{ activeSearch }, dispatch] = globalStateReducer;

  const handleSearchToggle = () => {
    dispatch({
      type: "setActiveSearch",
      payload: !activeSearch,
    });
  };

  return { activeSearch, handleSearchToggle };
}

/**
 * Listens for changes to the nav stack and opens or closes the live chat
 * widget to prevent it covering up menus.
 */
function LiveChatToggler() {
  const navStack = useNavStack();
  const liveChat = useLiveChat();

  useEffect(() => {
    if (0 < navStack.length) liveChat.hide();
    else liveChat.show();
  }, [navStack, liveChat]);

  return null;
}

/**
 * Listens for changes to the nav stack and emits analytics events.
 */
function AnalyticsSubscriber() {
  const navStack = useNavStack();
  const googleTagManager = useGoogleTagManager();

  const menuPathRef = useRef<string>("");

  useEffect(() => {
    // No menus open, do nothing.
    if (0 === navStack.length) return;

    /**
     * Compute and check a referentially equal string so we don't trigger events
     * for the same path over and over.
     */
    const menuPath = navStack.join(".");
    if (menuPathRef.current === menuPath) return;
    menuPathRef.current = menuPath;

    // Create an event.
    const event: NavigationMenuViewedEvent = {
      event: "Navigation Menu Viewed",
      properties: {
        menu_path: navStack,
        menu_depth: navStack.length,
        menu_id: navStack[navStack.length - 1],
      },
    };

    // Send to Google Tag Manager.
    googleTagManager.push(event);
  }, [navStack, googleTagManager]);

  return null;
}
