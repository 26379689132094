import { useCallback } from "react";

import { useSite } from "./useSite";
import { useSale } from "./useSale";
import { useCurrency } from "./useCurrency";

/**
 * This file contains utils copied from the legacy useCheckout and useCart hooks.
 *
 * When the consumers of these utils are refactored, we should remove these
 * in favor of the new hooks.
 */

export function useGetFormattedSalePriceFromTagOrRange() {
  const site = useSite();

  const { isSaleActive } = useSale();
  const isSale = isSaleActive();

  const { formatCurrency } = useCurrency();

  return useCallback(
    (presentmentPrice, saleTag, compareAtPrice = null, variants = []) => {
      const currencyCode = site.currencyCode;

      const prefix = currencyCode;
      const pricing = {
        price: null,
        salePrice: null,
      };

      if (presentmentPrice) {
        if (!("min_variant_price" in presentmentPrice)) {
          const price = parseFloat(presentmentPrice?.amount);
          const compareAt = parseFloat(compareAtPrice?.amount);
          pricing.price = compareAt > price ? compareAt : price;
          pricing.salePrice = compareAt > price ? price : null;
        } else {
          const price = presentmentPrice.min_variant_price?.find(
            (item) => item?.currency_code === currencyCode,
          )?.amount;
          const compareAt = variants?.reduce((result, variant) => {
            return variant?.presentment_prices
              ?.filter((price) => {
                return price?.compare_at_price?.currency_code === currencyCode;
              })
              ?.reduce((prev, curr) => {
                return Math.max(curr?.compare_at_price?.amount || 0, prev);
              }, result);
          }, 0);
          pricing.price = compareAt > price ? compareAt : price;
          pricing.salePrice = compareAt > price ? price : null;
        }
      }

      if (isNaN(pricing.price)) {
        return {
          price: undefined,
          salePrice: undefined,
        };
      }

      if (!isSale || !saleTag) {
        return {
          price: `${prefix}${formatCurrency(Math.ceil(pricing.price), currencyCode, 0)}`,
          salePrice:
            pricing.salePrice > 0
              ? `${prefix}${formatCurrency(Math.ceil(pricing.salePrice), currencyCode, 0)}`
              : undefined,
        };
      }

      const saleType = saleTag.split(":")[1];
      const saleNumber = saleTag.split(":")[3];

      switch (saleType) {
        case "percent":
          pricing.salePrice = pricing.price * (1 - Number(saleNumber) / 100);
          break;
        case "price":
        default:
          pricing.salePrice = Number(saleNumber);
          break;
      }

      if (isNaN(pricing.salePrice)) {
        return {
          price: `${prefix}${formatCurrency(Math.ceil(pricing.price), currencyCode, 0)}`,
        };
      }

      const salePrice = `${prefix}${
        saleType === "percent"
          ? formatCurrency(pricing.salePrice.toFixed(2), currencyCode, 0)
          : formatCurrency(Math.ceil(pricing.salePrice), currencyCode, 0)
      }`;
      const price = `${prefix}${formatCurrency(Number(pricing.price), currencyCode, 0)}`;

      return {
        salePrice,
        price,
      };
    },
    [site, isSale],
  );
}
