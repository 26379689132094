import React, { useEffect } from "react";

import { Sheet, CartDrawer, type CartDrawerProps } from "@bared/ui";

import {
  useCart,
  useCartToggle,
  useCartIsLoading,
  useCartLineItemRemove,
  useCartToggleAccessory,
} from "~/hooks/useCartNew";

import { useLiveChat } from "~/hooks/useLiveChat";

export function Cart() {
  const liveChat = useLiveChat();

  const { data: cart } = useCart();

  const { cartIsOpen, closeCart } = useCartToggle();

  const { removeLineItem } = useCartLineItemRemove();
  const { toggleAccessory } = useCartToggleAccessory();

  const isLoading = useCartIsLoading();

  const handleCloseCart: CartDrawerProps["onClose"] = () => {
    closeCart();
  };

  const handleLineRemove: CartDrawerProps["onLineRemove"] = ({
    cartLineId,
  }) => {
    removeLineItem({ cartLineId: cartLineId });
  };

  const handleAccessoryToggle: CartDrawerProps["onAccessoryToggle"] = ({
    enabled,
    cartLineId,
    accessoryType,
  }) => {
    toggleAccessory({ enabled, cartLineId, accessoryType });
  };

  const handleTooltipOpen = () => {
    const drawer = document.getElementById("cart-line-items");

    if (!drawer) return;

    drawer.style.overflowY = "hidden";
  };

  const handleTooltipClose = () => {
    const drawer = document.getElementById("cart-line-items");

    if (!drawer) return;

    drawer.style.removeProperty("overflow-y");
  };

  useEffect(() => {
    if (cartIsOpen) liveChat.hide();
    else liveChat.show();
  }, [cartIsOpen, liveChat]);

  return (
    <Sheet
      layout="condensed"
      isOpen={cartIsOpen}
      onClose={handleCloseCart}
      className="flex flex-col"
      title={
        !cart || 0 === cart.totalQuantity ? "Your Cart Is Empty" : "Your Cart"
      }
    >
      <CartDrawer
        cart={cart ?? null}
        isLoading={isLoading}
        onClose={handleCloseCart}
        onLineRemove={handleLineRemove}
        className="flex-1 overflow-y-auto"
        onAccessoryToggle={handleAccessoryToggle}
        onTooltipOpen={handleTooltipOpen}
        onTooltipClose={handleTooltipClose}
      />
    </Sheet>
  );
}
