import { useContext } from "react";

import { SanityContext } from "~/providers/SanityProvider";

/**
 * Return the sanity context.
 */
export function useSanityContext() {
  const context = useContext(SanityContext)!;

  if (!context) {
    throw new Error(
      "No SanityContext available. You probably forgot to add the SanityProvider.",
    );
  }

  return context;
}

/**
 * Return sanity client from context.
 */
export function useSanityClient() {
  return useSanityContext().sanityClient;
}
