import { useMemo, useCallback } from "react";

import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
  type AnalyticsEventName,
  type ShopifyPageViewPayload,
  type ShopifyAddToCartPayload,
  type ShopifyAnalyticsPayload,
} from "@shopify/hydrogen-react";

/**
 * Extract the base properties used for all Shopify events.
 * The types are not exported, so we are adapting ShopifyAddToCartPayload to access it.
 */
type ShopifyAnalyticsBasePayload = Omit<ShopifyAddToCartPayload, "cartId">;

/**
 * Construct an "ADD_TO_CART" payload, without the base properties.
 */
type ShopifyAnalyticsAddToCartPayload = Omit<
  ShopifyAddToCartPayload,
  keyof ShopifyAnalyticsBasePayload
>;

/**
 * Construct a "PAGE_VIEW" payload, without the base properties.
 */
type ShopifyAnalyticsPageViewPayload = Omit<
  ShopifyPageViewPayload,
  keyof ShopifyAnalyticsBasePayload
>;

import { useCustomer } from "./useSession";
import { useRouteLoaderData } from "./useRouteLoaderData";

export function useShopifyAnalytics() {
  const customer = useCustomer();
  const { shopAnalytics } = useRouteLoaderData("routes/$site");

  /**
   * Resolve the base payload sent with every Shopify event.
   * This needs to be a function because getClientBrowserParameters fetches the *current* location data.
   */
  const getBasePayload = useCallback((): ShopifyAnalyticsBasePayload => {
    return {
      ...shopAnalytics,
      ...getClientBrowserParameters(),
      hasUserConsent: true,
      shopifySalesChannel: "headless",
      ...(customer ? { customerId: customer.id } : {}),
    };
  }, [shopAnalytics, customer]);

  return useMemo(() => {
    function track(
      eventName: "ADD_TO_CART",
      payload: ShopifyAnalyticsAddToCartPayload,
    ): void;
    function track(
      eventName: "PAGE_VIEW",
      payload: ShopifyAnalyticsPageViewPayload,
    ): void;
    function track(
      eventName: keyof AnalyticsEventName,
      eventPayload:
        | ShopifyAnalyticsAddToCartPayload
        | ShopifyAnalyticsPageViewPayload,
    ): void {
      const payload: ShopifyAnalyticsPayload = {
        ...getBasePayload(),
        ...eventPayload,
      };

      sendShopifyAnalytics({ eventName, payload });
    }

    return { track };
  }, [getBasePayload]);
}
