import { useCallback, useMemo } from "react";

import { useCustomer } from "./useSession";
import { useRouteLoaderData } from "./useRouteLoaderData";

export const useSale = () => {
  const customer = useCustomer();

  const { market } = useRouteLoaderData("routes/$site");

  /**
   * Make a legacy sale object from the sale mode.
   * @todo Refactor this to the server when nothing consumes it anymore.
   */
  const sale = useMemo(
    () => ({
      enabled: "Disabled" !== market.saleMode,
      type:
        "General sale" === market.saleMode
          ? "sale"
          : "VIP sale" === market.saleMode
            ? "vip"
            : "Test sale" === market.saleMode
              ? "test"
              : "sale", // This seems odd, but the "type" stays as sale when disabled.
    }),
    [market],
  );

  /**
   * No idea why this is a callback rather than a memo.
   * It has lots of consumers though, so leaving it for now, with a plan
   * to gradually remove the consumers, and then this.
   */
  const isSaleActive = useCallback(() => {
    if (!sale.enabled) return false;

    if ("sale" === sale.type) {
      return true;
    } else if ("vip" === sale.type) {
      return !!customer?.tags?.includes("loyalty:VIP");
    } else if ("test" === sale.type) {
      return !!customer?.tags?.includes("loyalty:TEST");
    } else if ("vip-test" === sale.type) {
      return (
        !!customer?.tags?.includes("loyalty:TEST") &&
        !!customer?.tags?.includes("loyalty:VIP")
      );
    }

    return false;
  }, [sale, customer]);

  return { sale, isSaleActive };
};
