import { print } from "graphql";
import { useAtomValue } from "jotai";
import { useState, useEffect, useCallback, useMemo } from "react";

import type { DocumentNode } from "@apollo/client";
import type { ClientResponse } from "@shopify/storefront-api-client";

import { storefrontAtom } from "~/atoms/storefront";

/**
 * Return storefront client associated with current site.
 * @see https://shopify.dev/docs/api/hydrogen/2024-07/utilities/createstorefrontclient
 */
export function useStorefront() {
  return useAtomValue(storefrontAtom);
}

/**
 * Execute the given storefront query and return props similar to react-query.
 */
export function useStorefrontQuery(
  _query: string | DocumentNode,
  options: { variables?: Record<string, string | number | boolean> } = {},
) {
  // Convert query to string if provided a gql tagged literal.
  const query = useMemo(
    () => ("string" === typeof _query ? _query : print(_query)),
    [_query],
  );

  // Memoize the variables because consumers do not send a referentially equal object.
  const variables = useMemo(
    () => options.variables,
    [JSON.stringify(options.variables)],
  );

  const storefront = useStorefront();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ClientResponse<any> | null>(null);

  useEffect(() => {
    storefront
      .request(query, { variables })
      .then((res) => setData(res.data))
      .finally(() => setLoading(false));
  }, [query, variables, storefront]);

  return { data, loading };
}

/**
 * Return a function to execute the given mutation.
 */
export function useStorefrontMutation(query: string) {
  const storefront = useStorefront();

  return useCallback(
    (options: { variables?: Record<string, string> } = {}) => {
      const { variables = {} } = options;
      return storefront.request(query, { variables });
    },
    [query, storefront],
  );
}
