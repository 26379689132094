/**
 * This file contains global state which can be read or mutated from anywhere.
 * @see https://zustand.docs.pmnd.rs
 */

import { create } from "zustand";

interface State {
  cartIsOpen: boolean;
  openCart: () => void;
  closeCart: () => void;
}

export const useStore = create<State>((set) => ({
  cartIsOpen: false,
  openCart: () => set(() => ({ cartIsOpen: true })),
  closeCart: () => set(() => ({ cartIsOpen: false })),
}));
