import React, { useCallback } from "react";

import {
  UIProvider as _UIProvider,
  UIProviderProps as _UIProviderProps,
} from "@bared/ui";

import { useSite } from "~/hooks/useSite";
import { useSanityClient } from "~/hooks/sanity";
import { useGoogleTagManager } from "~/hooks/useGoogleTagManager";

import { Link } from "~/components/Link";

export interface UIProviderProps {
  children: React.ReactNode;
}

export function UIProvider({ children }: UIProviderProps) {
  const site = useSite();
  const googleTagManager = useGoogleTagManager();

  /** Handle tracking events emitted by the UI lib. */
  const onEvent = useCallback<NonNullable<_UIProviderProps["onEvent"]>>(
    (event) => {
      googleTagManager.push(event);
    },
    [googleTagManager],
  );

  /** Sanity client resolved from context. */
  const sanityClient = useSanityClient();

  return (
    <_UIProvider
      onEvent={onEvent}
      locale={site.locale}
      countryCode={site.countryCode}
      sanityClient={sanityClient}
      renderLink={(props) => <Link {...props} />}
    >
      {children}
    </_UIProvider>
  );
}

UIProvider.displayName = "UIProvider";
