import { globalHistory } from "@reach/router";
import React, { useEffect } from "react";

interface InputProps {
  children: React.ReactNode;
}

type OutputProps = InputProps;

export const withApp =
  (Component: React.FC<OutputProps>) =>
  ({ children }: InputProps) => {
    useEffect(() => {
      return globalHistory.listen(({ location }) => {
        const scrollRestorationRoutes = ["/products/", "/collections/"];

        if (
          !scrollRestorationRoutes.some((route) =>
            location.pathname.match(route),
          )
        ) {
          localStorage.removeItem("collection_scroll_position");
        }
      });
    }, []);

    return <Component>{children}</Component>;
  };
