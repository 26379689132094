import VisibilitySensor from "react-visibility-sensor";

import { type NormalisedProduct } from "@ts/components";
import { type Analytics } from "@ts/sanity";
import { type ProductVariant } from "@ts/shopify-storefront";
import { useCallback } from "react";
import { useCore } from "./useCore";
import { useKlaviyo } from "./useKlaviyo";
import { useShopify } from "./useShopify";

import { useSite } from "~/hooks/useSite";

export const useAnalytics = () => {
  const site = useSite();
  const { track } = useKlaviyo();
  const { productNormaliser } = useShopify();
  const {
    helpers: { decodeShopifyId },
  } = useCore();

  const formatPrice = (price) => {
    if (typeof price == "number" && !isNaN(price)) {
      return price.toFixed(2);
    }
    return price && price?.replace
      ? parseFloat(price.replace(/,/g, "."))?.toFixed(2)
      : 0;
  };

  const getVariantOptionValue = (options, selectedName) =>
    options?.find(
      ({ name }) => name?.toLowerCase() === selectedName?.toLowerCase(),
    )?.value || "";

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const isBrowser = () => typeof window !== "undefined";

  const decorateUrl = (url: string) => {
    if (isBrowser()) {
      const ga = window[window["GoogleAnalyticsObject"]];
      // @ts-ignore next-line
      if (ga && typeof ga.getAll === "function" && ga.plugins) {
        // @ts-ignore next-line
        const gaTracker = ga.getAll()[0];
        // @ts-ignore next-line
        return new window.gaplugins.Linker(gaTracker).decorate(url);
      }
    }
    return url;
  };

  const trackProductListImpression = useCallback(
    ({
      products,
      dataLayer,
    }: {
      products: unknown[];
      dataLayer?: { item_list_id?: string; item_list_name?: string };
    }) => {
      const productsList = products.map((product) => {
        const {
          collections,
          id,
          title,
          vendor,
          productType,
          metafields,
          variants,
          tags_category,
          variant_options,
        } = productNormaliser(product);
        return {
          collections,
          id,
          title,
          vendor,
          productType,
          metafields,
          variants,
          tags_category,
          variant_options,
        };
      });

      pushGA4Event({
        event: "view_item_list",
        ecommerce: {
          item_list_id: dataLayer?.item_list_id ?? "",
          item_list_name: dataLayer?.item_list_name ?? "Collection Results",
          currency: site.currencyCode,
          items: productsList.map((product, index: number) => ({
            item_id:
              typeof product.id === "number"
                ? `${product.id}`
                : decodeShopifyId(product.id, "Product"),
            item_name: product.title,
            item_brand: product.vendor,
            item_variant: product.variants?.[0]?.selectedOptions
              ? getVariantOptionValue(
                  product.variants?.[0]?.selectedOptions,
                  `Colour`,
                )
              : product.variant_options?.[0],
            item_category:
              product?.metafields?.find(
                (item) => item?.key === "netsuite_class",
              )?.value || product?.productType,
            item_category2: product?.metafields?.find(
              (item) => item?.key === "netsuite_category",
            )?.value,
            item_category3: product?.metafields?.find(
              (item) => item?.key === "netsuite_sub_category",
            )?.value,
            item_category4: product?.metafields?.find(
              (item) => item?.key === "netsuite_status",
            )?.value,
            index,
            quantity: 1,
            price:
              product.variants?.[0]?.priceV2?.amount ??
              formatPrice(product.variants?.[0]?.price),
            item_list_id: dataLayer?.item_list_id ?? "",
            item_list_name: dataLayer?.item_list_name ?? "Collection Results",
          })),
        },
      });
    },
    [site, productNormaliser, decodeShopifyId],
  );

  const trackProductImpression = useCallback(
    ({
      product,
      index,
      dataLayer,
    }: {
      product: any;
      index: number;
      dataLayer?: { item_list_id?: string; item_list_name?: string };
    }) => {
      const {
        id,
        title,
        vendor,
        price_min,
        variants,
        variant_options,
        productType,
        metafields,
      } = productNormaliser(product);
      if (title) {
        pushGA4Event({
          event: "view_item_list",
          ecommerce: {
            item_list_id: dataLayer?.item_list_id ?? "",
            item_list_name: dataLayer?.item_list_name ?? "Collection Results",
            currency: site.currencyCode,
            items: [
              {
                item_id:
                  typeof id === "number"
                    ? `${id}`
                    : decodeShopifyId(id, "Product"),
                item_name: title,
                item_brand: vendor,
                item_variant:
                  variants && variants[0]?.selectedOptions
                    ? getVariantOptionValue(
                        variants[0]?.selectedOptions,
                        "Colour",
                      )
                    : variant_options?.[0],
                item_category:
                  metafields?.find((item) => item?.key === "netsuite_class")
                    ?.value || productType,
                item_category2: metafields?.find(
                  (item) => item?.key === "netsuite_category",
                )?.value,
                item_category3: metafields?.find(
                  (item) => item?.key === "netsuite_sub_category",
                )?.value,
                item_category4: metafields?.find(
                  (item) => item?.key === "netsuite_status",
                )?.value,
                item_list_name:
                  dataLayer?.item_list_name ?? "Collection Results",
                item_list_id: dataLayer?.item_list_id ?? "",
                index,
                quantity: 1,
                price: variants?.[0]?.priceV2?.amount ?? formatPrice(price_min),
              },
            ],
          },
        });
      }
    },
    [site, productNormaliser, decodeShopifyId],
  );

  const trackProductClick = useCallback(
    ({
      product,
      colour,
      index,
      dataLayer,
    }: {
      product: any;
      colour: string;
      index: number;
      dataLayer?: { item_list_id?: string; item_list_name?: string };
    }) => {
      const {
        id,
        title,
        vendor,
        price_min,
        variants,
        variant_options,
        productType,
        metafields,
      } = productNormaliser(product);

      if (title) {
        pushGA4Event({
          event: "select_item",
          ecommerce: {
            item_list_id: dataLayer?.item_list_id ?? "",
            item_list_name: dataLayer?.item_list_name ?? "Collection Results",
            currency: site.currencyCode,
            items: [
              {
                item_id: id,
                item_name: title,
                item_brand: vendor,
                item_variant:
                  variants && variants[0]?.selectedOptions
                    ? getVariantOptionValue(
                        variants[0]?.selectedOptions,
                        "Colour",
                      )
                    : variant_options?.[0],
                item_category:
                  metafields?.find((item) => item?.key === "netsuite_class")
                    ?.value || productType,
                item_category2: metafields?.find(
                  (item) => item?.key === "netsuite_category",
                )?.value,
                item_category3: metafields?.find(
                  (item) => item?.key === "netsuite_sub_category",
                )?.value,
                item_category4: metafields?.find(
                  (item) => item?.key === "netsuite_status",
                )?.value,
                item_list_name:
                  dataLayer?.item_list_name ?? "Collection Results",
                item_list_id: dataLayer?.item_list_id ?? "",
                colour,
                index,
                quantity: 1,
                price: variants?.[0]?.priceV2?.amount ?? formatPrice(price_min),
              },
            ],
          },
        });
      }
    },
    [site, productNormaliser, decodeShopifyId],
  );

  const trackProductView = useCallback(
    (product: NormalisedProduct, variant: ProductVariant) => {
      const { id, productType, title, vendor, metafields } =
        productNormaliser(product);

      if (title) {
        const dataLayer = {
          event: "view_item",
          ecommerce: {
            currency: site.currencyCode,
            items: [
              {
                item_id: decodeShopifyId(id, "Product"),
                item_name: title,
                item_brand: vendor,
                item_variant: getVariantOptionValue(
                  variant?.selectedOptions ||
                    (product?.variants &&
                      product?.variants[0]?.selectedOptions),
                  `Colour`,
                ),
                item_category:
                  metafields?.find((item) => item?.key === "netsuite_class")
                    ?.value || productType,
                item_category2: metafields?.find(
                  (item) => item?.key === "netsuite_category",
                )?.value,
                item_category3: metafields?.find(
                  (item) => item?.key === "netsuite_sub_category",
                )?.value,
                item_category4: metafields?.find(
                  (item) => item?.key === "netsuite_status",
                )?.value,
                quantity: 1,
                price: formatPrice(
                  variant?.priceV2?.amount ||
                    (product?.variants &&
                      product?.variants[0]?.priceV2?.amount),
                ),
              },
            ],
          },
        };

        pushGA4Event(dataLayer);
      }
    },
    [site, productNormaliser, decodeShopifyId],
  );

  const trackWishlistUpdate = useCallback(
    (type, product) => {
      if (product?.title) {
        const dataLayer = {
          event: type === "add" ? "add_to_wishlist" : "remove_from_wishlist",
          ecommerce: {
            currency: site.currencyCode,
            items: [
              {
                item_id:
                  decodeShopifyId(
                    product?.selectedVariant?.id,
                    "ProductVariant",
                  ) ?? product?.id,
                item_name: product?.title,
                item_brand: product?.vendor,
                item_variant: product?.variant_options
                  ? product?.variant_options?.[0]
                  : getVariantOptionValue(
                      product?.selectedVariant?.selectedOptions ??
                        product?.variants?.[0]?.selectedOptions,
                      `Colour`,
                    ),
                item_category:
                  product?.metafields?.find(
                    (item) => item?.key === "netsuite_class",
                  )?.value || product?.productType,
                item_category2: product?.metafields?.find(
                  (item) => item?.key === "netsuite_category",
                )?.value,
                item_category3: product?.metafields?.find(
                  (item) => item?.key === "netsuite_sub_category",
                )?.value,
                item_category4: product?.metafields?.find(
                  (item) => item?.key === "netsuite_status",
                )?.value,
                price: formatPrice(
                  product?.selectedVariant?.priceV2?.amount ??
                    product?.variants?.[0]?.priceV2?.amount ??
                    product?.price_min,
                ),
              },
            ],
          },
        };

        pushGA4Event(dataLayer);
      }
    },
    [site, decodeShopifyId, track],
  );

  const trackPromoImpression = useCallback(
    ({ analyticsId, name, creative, position }: Partial<Analytics>) => {
      if (name) {
        const dataLayer = {
          event: "view_promotion",
          ecommerce: {
            currency: site.currencyCode,
            items: [
              {
                promotion_id: analyticsId,
                promotion_name: name,
                creative_name: creative,
                creative_slot: position,
              },
            ],
          },
        };

        pushGA4Event(dataLayer);
      }
    },
    [site],
  );

  const trackPromoClick = useCallback(
    ({ analyticsId, name, creative, position }: Partial<Analytics>) => {
      if (name) {
        const dataLayer = {
          event: "select_promotion",
          ecommerce: {
            currency: site.currencyCode,
            items: [
              {
                promotion_id: analyticsId,
                promotion_name: name,
                creative_name: creative,
                creative_slot: position,
              },
            ],
          },
        };

        pushGA4Event(dataLayer);
      }
    },
    [site],
  );

  const trackLogin = useCallback(async (method) => {
    if (method) {
      const dataLayer = {
        event: "login",
        ecommerce: {
          method: capitalizeFirstLetter(method),
        },
      };

      pushGA4Event(dataLayer);
    }
  }, []);

  const trackSignup = useCallback(async (method) => {
    if (method) {
      const dataLayer = {
        event: "sign_up",
        ecommerce: {
          method: capitalizeFirstLetter(method),
        },
      };

      pushGA4Event(dataLayer);
    }
  }, []);

  const trackShare = useCallback(async (method, type, id) => {
    if (method) {
      const dataLayer = {
        event: "share",
        ecommerce: {
          method: capitalizeFirstLetter(method),
          content_type: type,
          content_id: id,
        },
      };

      pushGA4Event(dataLayer);
    }
  }, []);

  const trackClick = useCallback(async (type, id) => {
    if (type) {
      const dataLayer = {
        event: "select_content",
        ecommerce: {
          content_type: type,
          content_id: id,
        },
      };

      pushGA4Event(dataLayer);
    }
  }, []);

  const trackSearch = useCallback(async (term) => {
    if (term) {
      const dataLayer = {
        event: "search",
        ecommerce: {
          search_term: term,
        },
      };

      pushGA4Event(dataLayer);
    }
  }, []);

  return {
    capitalizeFirstLetter,
    formatPrice,
    getVariantOptionValue,
    trackProductListImpression,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackWishlistUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    decorateUrl,
    VisibilitySensor,
  };
};

function pushGA4Event(payload) {
  if (
    !payload ||
    "undefined" === typeof window ||
    "undefined" === typeof window.dataLayer
  ) {
    return;
  }

  // Clear previous ecommerce data if needed.
  if (payload.ecommerce) {
    window.dataLayer.push({ ecommerce: null });
  }

  window.dataLayer.push(payload);
}
